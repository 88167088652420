import React, {
    Component, useLayoutEffect, useRef,
    useState
} from 'react';

import Chart
    from "react-apexcharts";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);


function ExecutionVenueChart(props) {
    const chart = useRef(null);

    useLayoutEffect(() => {
        let chart = am4core.create("chartdiv6", am4charts.PieChart);


        chart.data = [
            {
                "country": "Austrafan Stock Exchange",
                "litres": 61
            },
            {
                "country": "Chi-X Australia",
                "litres": 16
            },
            {
                "country": "Liquidnet",
                "litres": 12
            },
            {
                "country": "ASX Centre Point",
                "litres": 5
            },
            {
                "country": "ASXT ",
                "litres": 2
            },
            {
                "country": "Off Exchange",
                "litres": 2
            },
            {
                "country": "Canaccord Cash Desk",
                "litres": 1
            },
            {
                "country": "POSIT",
                "litres": 1
            },
            {
                "country": "Chi-X Australia Mid-Point Venue",
                "litres": 0
            },
            {
                "country": "JP Morgan JPMX",
                "litres": 0
            },
            {
                "country": "Credit Suisse Crossfinder",
                "litres": 0
            },
            {
                "country": "Morgan Stanley Pool",
                "litres": 0
            },
            {
                "country": "Macquarfe Dark Pool",
                "litres": 0
            }
        ];

// Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "litres";
        pieSeries.dataFields.category = "country";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeOpacity = 1;
        // pieSeries.slices.template.cornerRadius = 100
// This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        //   pieSeries.hiddenState.properties.endAngle = -90;
        //  pieSeries.hiddenState.properties.startAngle = -90;

        pieSeries.ticks.template.disabled = true;
        pieSeries.labels.template.disabled = true;
        chart.hiddenState.properties.radius = am4core.percent(0);
        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";
        var marker = chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12, 12, 12, 12);
        chart.legend.markers.template.width = 10;
        chart.legend.markers.template.height = 10;
        chart.legend.labels.template.fontSize = 11
        chart.legend.fontSize = 11;
        pieSeries.tooltip.getFillFromObject = false;
        pieSeries.tooltip.getStrokeFromObject = false;
        pieSeries.tooltip.background.stroke = am4core.color("#000");
        pieSeries.tooltip.background.fill = am4core.color("#000");
        pieSeries.colors.step = 3;
        return () => {
            chart.dispose();
        };
    }, []);

    return (
        <div id="chartdiv6" style={{width: "100%", height: "600px"}}></div>
    );
}

const ChartExecutionVenue = () => {

    return <>
        <div className="wrapper-chart">
            <div className="top-row">
                <h3>Execution Venue</h3>
            </div>
            <div className="chart-img">
                <ExecutionVenueChart/>
            </div>
        </div>
    </>
}
export default ChartExecutionVenue

