import React, {useState} from 'react';
import Overview from "./Overview/Overview";
import Execution
    from "./Execution/Execution";


const StrategyOverviewPage = ({setClosePopup, strategyOverviewHits, setStrategyOverviewHits}) => {
const [active, changeActive] = useState(true)

    return <>
       <h1>Strategy Overview</h1>
        <div className="row-tab">
            <span className={active ? 'overview active' :'overview' } onClick={()=>{changeActive(true)}}>Overview</span>
            <span className={!active ? 'execution active' :'execution' } onClick={()=>{changeActive(false)}}>Execution</span>
        </div>
        {active ?  <Overview setClosePopup={setClosePopup} strategyOverviewHits={strategyOverviewHits} setStrategyOverviewHits={setStrategyOverviewHits}/> : <Execution/>}
    </>
}
export default StrategyOverviewPage

