import React, {
    Component, useLayoutEffect, useRef,
    useState
} from 'react';
import {setUrl2} from "../../../store/configureStore";
import Select from "react-select";
import {customStyles} from "../../StrategyOverviewPage/ChangeStrategy/ChangeStrategy";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import readXlsxFile from "read-excel-file";


am4core.useTheme(am4themes_animated);


function MarketEquatyPerfomanceChart(props) {
    const chart = useRef(null);

    useLayoutEffect(() => {
        let chart = am4core.create("chartdiv2", am4charts.XYChart);
        // chart.paddingLeft = 50;
        // chart.bottomAxesContainer.background.fill = am4core.color("#F8FAFE");
        // chart.bottomAxesContainer.marginTop = 30;
        let firstData


        const map = {
            'date': 'Date',
            'value1': 'CBA',
            'value2': 'NAB',
            'value3': 'ANZ',
            'value4': 'WBC',
            'value5': 'Red Volume',
            'value6': 'Green Volume',
            'close': 'Close',
        }




        const schema = {
            'Date': {
                // JSON object property name.
                prop: 'date',
                type: Date
            },
            'CBA': {
                // JSON object property name.
                prop: 'value1',
                //  type: Number
                type: (value) => (value * 100).toFixed(2)
            },
            'NAB': {
                // JSON object property name.
                prop: 'value2',
                // type: Number
                type: (value) => (value * 100).toFixed(2)
            },
            'ANZ': {
                // JSON object property name.
                prop: 'value3',
                // type: Number
                type: (value) => (value * 100).toFixed(2)
            },
            'WBC': {
                // JSON object property name.
                prop: 'value4',
                // type: Number
                type: (value) => (value * 100).toFixed(2)
            },
            'Red Volume': {
                // JSON object property name.
                prop: 'value5',
                type: Number
            },
            'Green Volume': {
                // JSON object property name.
                prop: 'value6',
                type: Number
            },
            'Close': {
                // JSON object property name.
                prop: 'close',
                type: Number
            },
        }


        fetch("/skripApp/public/data.xlsx")
            .then(response => response.arrayBuffer())
            .then(text => {
                readXlsxFile(text, {sheet: 'Competitor Perf. Graph UPDATED', map, schema}).then((data2) => {

                    var showPricent = true;
                    chart.data = data2.rows

                    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                    dateAxis.renderer.minGridDistance = 60;
                    dateAxis.renderer.background.fill = am4core.color("#F8FAFE");
                    dateAxis.renderer.marginTop = 20;
                    dateAxis.renderer.grid.template.location = 0;
                    dateAxis.renderer.labels.template.fill = am4core.color("#B3B6C1");
                    dateAxis.renderer.line.strokeOpacity = 1;
                    dateAxis.renderer.line.strokeWidth = 2;
                    dateAxis.renderer.line.stroke = am4core.color("#D5DDF1")
                    dateAxis.renderer.line.strokeWidth = 2;
                    dateAxis.renderer.line.stroke = am4core.color("#D5DDF1");
                    dateAxis.renderer.grid.template.strokeDasharray = 4
                    dateAxis.renderer.labels.template.location = 0.5
                    // y - axios
                    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                    valueAxis.renderer.opposite = false;
                    valueAxis.renderer.labels.template.adapter.add("text", (label, target, key) => {
                        console.log(label)
                        return label ? `${label}%` : null
                    });

                    let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
                    valueAxis2.renderer.opposite = true
                    valueAxis2.hidden = true
                    valueAxis2.renderer.labels.template.adapter.add("text", (label, target, key) => {
                        return label ? '$' + label.split(',')[0] + 'm' : null
                    });
                    let valueAxis3 = chart.yAxes.push(new am4charts.ValueAxis());
                    valueAxis3.disabled = true
                    valueAxis3.min = 0
                    valueAxis3.renderer.opposite = false
                    valueAxis3.renderer.labels.template.adapter.add("text", (label, target, key) => {
                        return label ? label : null
                    });
                    // valueAxis2.syncWithAxis = valueAxis
                    setValueAxis(valueAxis)
                    setValueAxis(valueAxis2)
                    setValueAxis(valueAxis3)

                    function setValueAxis(valueAxis) {
                        valueAxis.renderer.labels.template.fill = am4core.color("#B3B6C1");
                        valueAxis.strictMinMax = true;
                        valueAxis.renderer.minGridDistance = 60;
                        valueAxis.renderer.labels.template.fill = am4core.color("#B3B6C1");
                        valueAxis.renderer.labels.template.fontSize = 11;
                        valueAxis.renderer.grid.template.strokeDasharray = 4;
                        valueAxis.renderer.line.strokeOpacity = 1;
                        valueAxis.renderer.line.strokeWidth = 2;
                        valueAxis.renderer.line.stroke = "#B3B6C1";
                        valueAxis.renderer.labels.template.fill = "#B3B6C1";
                    }
                    valueAxis.adapter.add("getTooltipText", function(text, target) {
                        console.log(text)
                        return  text + "%";
                    });
                    chart.cursor = new am4charts.XYCursor()
                    // chart.cursor.maxTooltipDistance = -1;
                    chart.cursor.lineX.stroke = am4core.color("#58595B");
                    chart.cursor.lineX.strokeWidth = 1;
                    chart.cursor.lineX.strokeDasharray = "3";
                    chart.cursor.lineY.stroke = am4core.color("#58595B");
                    chart.cursor.lineY.strokeWidth = 1;
                    chart.cursor.lineY.strokeDasharray = "3";
                    chart.cursor.tooltip.fill = am4core.color("#58595B");
let arrBullet = []
                    function createSeries(value, name, color, seriescharts) {
                        let series
                        if (seriescharts == 'LineSeries') {

                            series = chart.series.push(new am4charts.LineSeries());
                            series.dataFields.valueY = value;
                            series.dataFields.dateX = "date";
                            series.name = name;
                            series.tooltipText = '{dateX} ' + "[ " + color + " ]●[/] " + ' ' + '{valueY}' + '%'
                            series.tooltip.getFillFromObject = false;
                            series.tooltip.getStrokeFromObject = false;
                            series.tooltip.background.stroke = am4core.color("#000");
                            series.tooltip.background.fill = am4core.color("#000");
                            series.fillOpacity = 0;
                            series.strokeWidth = 2;
                            series.stroke = am4core.color(color);
                            series.fill = am4core.color(color);
                            series.tensionX = .88
                            series.hiddenInLegend = false
                            series.yAxis = valueAxis;
                            let bullet = series.bullets.push(new am4charts.CircleBullet());
                            arrBullet.push(bullet)
                            bullet.circle.stroke = am4core.color("#fff");
                            bullet.circle.strokeWidth = 2;
                            bullet.fillOpacity = 0;
                            bullet.strokeOpacity = 0;
                            let bulletState = bullet.states.create("hover");
                            bulletState.properties.fillOpacity = 1;
                            bulletState.properties.strokeOpacity = 1;

                            if (value === 'close') {
                                // valueAxis3.disabled= false
                                // valueAxis.disabled =true
                                series.tooltipText = '{dataX} ' + "[ " + color + " ]●[/] " + ' ' + '{valueY}' + '$'
                                series.hiddenInLegend = true;
                            } else {

                            }
                        } else {
                            series = chart.series.push(new am4charts.ColumnSeries());
                            series.dataFields.valueY = value;
                            series.dataFields.dateX = "date";
                            series.yAxis = valueAxis2;
                            series.name = name;
                            series.tooltipText = '{dateX}'  + "[ " + color + " ]●[/] " + '{valueY}'
                            series.tooltip.getFillFromObject = false;
                            series.tooltip.getStrokeFromObject = false;
                            series.tooltip.background.stroke = am4core.color("#000");
                            series.tooltip.background.fill = am4core.color("#000");
                            series.strokeWidth = 0;
                            series.clustered = false;
                            series.columns.template.width = am4core.percent(15);
                            series.columns.template.fill = am4core.color(color);
                            series.hiddenInLegend = true;
                        }
                        return series;
                    }

                    let series2 = createSeries('value2', 'ELC', '#C619D6', 'LineSeries')
                    let series7 = createSeries('close', 'ELC2', '#C619D6', 'LineSeries')
                    let series1 = createSeries('value1', 'CO1', '#37BCAE', 'LineSeries')
                    let series3 = createSeries('value3', 'CO2', '#1964D6', 'LineSeries')
                    let series4 = createSeries('value4', 'CO3', 'orange', 'LineSeries')
                    let series5 = createSeries('value5', 'Red Volume', '#EB5757', 'ColumnSeries')
                    let series6 = createSeries('value6', 'Green Volume', '#37BCAE', 'ColumnSeries')

                    series7.disabled = true
                    series7.yAxis = valueAxis3;
                    valueAxis3.disabled = true
                    chart.legend = new am4charts.Legend();
                    chart.legend.paddingRight = 250
                    chart.legend.marginTop = -20

                    chart.legend.position = 'top'
                    chart.legend.contentAlign = "right";
                    chart.legend.useDefaultMarker = true;
                    let legendObj = {
                        ELC: true,
                        CO1: true,
                        CO2: true,
                        CO3: true,

                    }
                    let arr
                    let arr2

                    chart.legend.itemContainers.template.events.on("hit", function (ev) {
                        legendObj[ev.target.dataItem.dataContext.name] = !legendObj[ev.target.dataItem.dataContext.name]
                        legendObj.ELC = true
                        arr2 = Object.values(legendObj)
                        // series2.events.on("hidden", function () {
                            legendObj.ELC = true
                            // series2.show()
                            // series2.events.off("hidden");
                        // });
                        // series7.events.on("hidden", function () {
                        //     legendObj.ELC = true
                        //     series7.show()
                        //     series7.hiddenInLegend = false;
                        //     series7.events.off("hidden");
                        // });
console.log(series2)
                        if (arr2.length === 4) {
                            arr = arr2.filter(el => el === false)
                            if (arr.length === 3 && legendObj.ELC === true ) {
                                showPricent = false;

                                // setTimeout(function () {
                                    // if (!series7) {
                                    valueAxis3.disabled = false
                                    valueAxis.disabled = true
                                    series7.disabled = false
                                    series2.disabled = true
                                        arrBullet[0].disabled = true
                                        arrBullet[1].disabled = false

                                // series2.hide()
                                    // series2.hiddenInLegend = true;
                                    // series7.hiddenInLegend = false;
                                    // series7.yAxis = valueAxis3;

                                    // }
                               if( ev.target.dataItem.dataContext.name == 'ELC'){
                                   series2.disabled = false
                                   // series2.hide()
                               }
                                // }, 300)
                            } else {
                                showPricent = true;
                                series2.events.on("hidden", function () {
                                    legendObj.ELC = true
                                    series2.show()
                                    series2.events.off("hidden");
                                });
                                // if (series7) {
                                series2.disabled = false
                                valueAxis.disabled = false
                                valueAxis3.disabled = true
                                arrBullet[0].disabled = false
                                arrBullet[1].disabled = true
                                // series2.hiddenInLegend = false;
                                // series7.hiddenInLegend = true;
                                // if (chart.series.indexOf(series7)) {
                                series7.disabled = true
                                // chart.series.removeIndex(
                                //     chart.series.indexOf(series7)
                                // )
                                // series7 = false
                                // }
                                // }
                            }
                        }
                    });
                    const marker = chart.legend.markers.template;
                    const markerColumn = marker.children.getIndex(0);
                    markerColumn.cornerRadius(2, 2, 2, 2);
                    const markerColumnActiveState = markerColumn.states.getKey("active");
                    markerColumn.defaultState.properties.fillOpacity = 1;
                    markerColumn.defaultState.properties.strokeOpacity = 0;


                    // Add custom image instead
                    const checkbox = marker.createChild(am4core.Image);
                    checkbox.width = 22;
                    checkbox.height = 22;
                    checkbox.verticalCenter = "top";
                    checkbox.horizontalCenter = "left";
                    checkbox.href = "/skripApp/build/images/arrow-chek.svg";
                    checkbox.dx = 1;
                    checkbox.dy = 1;
                })
            })
    }, []);
    return (
        <div id="chartdiv2" style={{width: "100%", height: "500px"}}></div>
    );
}

const ChartMarketEquityPerformance = () => {
    let options1 = [
        {value: '12 Month', label: '12 Month'},
        {value: '12 Month1', label: '12 Month1'},
        {value: '12 Month2', label: '12 Month2'},


    ]
    return <>
        <div className="wrapper-chart chart-market-equity-performance">
            <div className="top-row">
                <h3>Market Equity Performance</h3>
                <div className="wrapper-select-settings">
                    {/*<div className="wrapper-chart-label">*/}
                    {/*<img src={setUrl2("images/chart-label.png")} alt=""/>*/}
                    {/*</div>*/}
                    <Select
                        defaultValue={options1[0].value}
                        placeholder={options1[0].value}
                        options={options1}
                        name='select-chart-data'
                        styles={customStyles}
                        className='select-chart-data select'
                        width='inherit'
                    />

                    <span className='settings'>settings</span>
                </div>
            </div>
            <div className="chart-img">
                <MarketEquatyPerfomanceChart/>
                {/*<img src={setUrl2("images/chart-market-equity-performance.png")} alt=""/>*/}
            </div>
        </div>
    </>
}
export default ChartMarketEquityPerformance

