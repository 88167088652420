import React from 'react';
import RowTableTitle from "./RowTableTitle";
import RowTableData from "./RowTableData";
import {Form} from "formik";
import NewStrategyPage from "../../NewStrategyPage/NewStrategyPage";
import SavedStrategyPage from "../SavedStrategyPage";

const savedStrategy1 = [
    {

        name: 'Sample Raising',
        status: 'Authorised',
        amount: '500m',
        date: '2021',
        active: false
    }
]
const savedStrategy2 = [
    {

        name: 'BHP Q3Capital Return',
        status: 'Authorised',
        amount: '350m',
        date: '2021',
        active: true
    }, {

        name: 'BHP - WAIO Red Kangaroo Extension',
        status: 'Authorised',
        amount: '1.2bn ',
        date: '2021',
        active: true
    }, {

        name: 'BHP - Project Rugby Equity Takeover ',
        status: 'Authorised',
        amount: '13bn ',
        date: '2021-22',
        active: false
    },
]
const savedStrategy3 = [
    {

        name: 'Sample Buyback FY19',
        status: 'Authorised',
        amount: '750m',
        date: '2019',
        active: false
    }, {

        name: 'Sample Raising FY20',
        status: 'Authorised',
        amount: '2.6bn',
        date: '2019',
        active: false
    },
    // {
    //
    //     name: 'BHP - Red Baron Takeover',
    //     status: 'Authorised',
    //     amount: '2.1bn',
    //     date: '2018',
    //     active: false
    // }, {
    //
    //     name: 'BHP - Balance Sheet Strengthening ',
    //     status: 'Authorised',
    //     amount: '850m',
    //     date: '2017',
    //     active: false
    // },
]
const StrategyList = ({setClosePopup, setExecutionPopup, setSavePopup, setDeletePopup,  setCumulativeData,
                          setCapitalData, setIdStrategy}) => {

    let savedStrategy2 = null
    if(localStorage.getItem('strategies') ){
   savedStrategy2 = Object.entries(JSON.parse( localStorage.getItem('strategies') ))
}
    return <>
        <div className="wrapper-list-strategy">
            <h3>Pending Dual Authorisation</h3>
            <div className="list-strategy">
                <RowTableTitle/>

                {savedStrategy1.map((el, i) => {
                    return <RowTableData key={i} {...el}  setClosePopup = {setClosePopup}  setDeletePopup = {setDeletePopup}    />
                })}
            </div>
            <h3>Saved Strategies</h3>
            <div className="list-strategy">
                <RowTableTitle/>

                {savedStrategy2 ? savedStrategy2.map((el, i) => {
                    return <RowTableData key={el[0]} {...el[1]} id={el[0]}   setClosePopup = {setClosePopup}  setDeletePopup = {setDeletePopup}
                                         setCumulativeData={ setCumulativeData}
                                         setCapitalData={setCapitalData}
                                         setIdStrategy={setIdStrategy}/>
                }) : <span className='no-strategies'>no saved strategies</span> }
            </div>
            <h3>Complete/Terminated Strategies</h3>
            <div className="list-strategy">
                <RowTableTitle/>
                {savedStrategy3.map((el, i) => {
                    return <RowTableData {...el} key={i}  setClosePopup = {setClosePopup}  setDeletePopup = {setDeletePopup}/>
                })}
            </div>
        </div>
        <div className="fixed-row-btns saved-row-btn">
            <button onClick={() => {
                setClosePopup(true)
                setDeletePopup(true)
            }} className='delete'>Delete (<span>2</span>)
            </button>
            <div className="right-side">
                <button onClick={() => {
                    setClosePopup(true)
                    setExecutionPopup(true)
                }} className='execute-btn bg-none'>Execute
                </button>
                <button onClick={() => {
                    setClosePopup(true)
                    setSavePopup(true)
                }} type="submit" className='save-btn bg-full'>Save
                </button>
            </div>
        </div>
    </>
}
export default StrategyList

