import React, {
    Component,
    useState
} from 'react';
import OneCapital
    from "./OneCapital/OneCapital";
import Chart
    from "react-apexcharts";

let capitalData = [

    {
        id: 1, title: 'Total Capital Issuance', num: 3504.52, remaining: '$745.48m remaining', income: '+ $139.32m', category: 'category1', status: 'entrance', prefix: '$ ', suffix: "m"

    }, {
        id: 2, title: 'Capital Base Issuance', num: 6.22, remaining: '178,971,489 new shares have been issued.', income: '+ 0.19%', category: 'category2', status: 'entrance', prefix: '', suffix: "%"

    }, {
        id: 3, title: 'VWAP Transaction Price', num: 19.9, remaining: '', income: '+ $0.22 ', category: 'category3', status: 'entrance', prefix: '$ ', suffix: ""

    }, {
        id: 4, title: 'Total Capital Boughtback', num: 836.55, remaining: '$176.75m remaining ', income: '+ $35.90m', category: 'category4', status: 'exit', prefix: '$ ', suffix: "m"

    }, {
        id: 5, title: 'Capital Base Boughtback', num: 3.21, remaining: '47,487,996 shares have been boughtback. ', income: '- 0.2%', category: 'category5', status: 'exit', prefix: '', suffix: "%"

    }, {
        id: 6, title: 'VWAP Transaction Price', num: 234.23, remaining: '', income: '+ $35.90', category: 'category6', status: 'exit', prefix: '$ ', suffix: ""

    },

]

const CapitalInfo = () => {

    return <>
        <div className="row-capital">
            {capitalData.map(function (el, i) {

                return <OneCapital key={el.id} {...el}/>

            })}
        </div>


    </>
}
export default CapitalInfo

