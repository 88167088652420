import React
    from 'react';
import CapitalInfo
    from "../../GraphsCharts/CapitalInfo";
import ChartExecutionAgainstPlan
    from "../../GraphsCharts/ChartExecutionAgainstPlan/ChartExecutionAgainstPlan";
import ChartCumulativeCapitalChange
    from "../../GraphsCharts/ChartCumulativeCapitalChange/ChartCumulativeCapitalChange";
import ChartMarketEquityPerformance
    from "../../GraphsCharts/ChartMarketEquityPerformance/ChartMarketEquityPerformance";
import ChangeStrategy
    , {customStyles} from "../ChangeStrategy/ChangeStrategy";
import {setUrl2} from "../../../store/configureStore";
import Select from "react-select";
import ChartCumulativeCapitalChangeOverveiw
    from "../../GraphsCharts/ChartCumulativeCapitalChangeOverveiw/ChartCumulativeCapitalChangeOverveiw";


const Overview = ({setClosePopup, strategyOverviewHits, setStrategyOverviewHits}) => {
    let options1 = [
        {value: 'Annual', label: 'Annual'},
        {value: 'Annual1', label: 'Annual1'},


    ]
    const handleChange = (e) => {

    }
    return <>
        <ChangeStrategy/>
        <CapitalInfo/>
        <ChartExecutionAgainstPlan/>
        <ChartCumulativeCapitalChangeOverveiw type={'RaiseCapital'}>
            <div className="top-row">
                <h3>Cumulative Capital Change</h3>
                <Select
                    defaultValue={options1[0].value}
                    placeholder={options1[0].value}
                    options={options1}
                    name='select-chart-data'
                    onChange={(values) => handleChange(values)}
                    className='select-chart-data select'
                    styles={customStyles}
                    width='inherit'
                />

            </div>

        </ChartCumulativeCapitalChangeOverveiw>
        <ChartMarketEquityPerformance/>
        <div className="fixed-row-btns">
            <button className='modify-btn bg-none'>Modify</button>
            <button onClick={() => {
                setStrategyOverviewHits(true)
                setClosePopup(true)
            }} className='halt-btn bg-full'>Halt
            </button>
        </div>
    </>
}
export default Overview

