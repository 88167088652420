import React, {Component} from 'react';
import Slider from "react-slick";
import {setUrl2} from "../../store/configureStore";

export default class SliderLogin extends Component {
    render() {
        const settings = {
            dots: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false
        };
        return (


                <Slider {...settings}>
                    <div className='slide'>
                        <div className="wrapper-img">
                            <img src={setUrl2("images/image-slide1.png")} alt="img"/>
                        </div>
                        <h3>Strategy Overview</h3>
                        <p>Plan and track your strategies for capital management</p>
                    </div>
                     <div className='slide'>
                        <div className="wrapper-img">
                            <img src={setUrl2("images/image-slide1.png")} alt="img"/>
                        </div>
                        <h3>Strategy Overview</h3>
                        <p>Plan and track your strategies for capital management</p>
                    </div>
                     <div className='slide'>
                        <div className="wrapper-img">
                            <img src={setUrl2("images/image-slide1.png")} alt="img"/>
                        </div>
                        <h3>Strategy Overview</h3>
                        <p>Plan and track your strategies for capital management</p>
                    </div>
                     <div className='slide'>
                        <div className="wrapper-img">
                            <img src={setUrl2("images/image-slide1.png")} alt="img"/>
                        </div>
                        <h3>Strategy Overview</h3>
                        <p>Plan and track your strategies for capital management</p>
                    </div>


                </Slider>

        );
    }
}
