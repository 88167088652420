import React, {useState} from 'react';
import Auth from "./Auth/Auth";
import FullContent from "./FullContent/FullContent";
import SignUp from "./SignUp/SignUp";
import Login from "./Login/Login";
import {Redirect, Router, Route, Switch} from "react-router-dom";
import {setUrl} from "../store/configureStore";
import StrategyOverviewPage from "./StrategyOverviewPage/StrategyOverviewPage";

export const AuthContext = React.createContext();
const App = () => {
const [auth, setAuth] = useState(false)

    return<>
        <AuthContext.Provider value={{ auth, setAuth }}>
        {auth ? <>
                <Auth/>
                <FullContent />
                <Redirect to={setUrl('/strategy-overview')} />
                </> :<>


            <Switch>
                <Route  path={setUrl('/login')}   render={()=><Login  />} />
                <Route  path={setUrl('/')}   render={()=><SignUp  />} />
            </Switch>
            </>

        }
            {/*<FullContent />*/}
        </AuthContext.Provider>
    </>
}
export default App
