import React, {
    Component, useLayoutEffect, useRef,
    useState
} from 'react';

import Chart
    from "react-apexcharts";
import {setUrl2} from "../../../store/configureStore";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

function CamulativeCapitalChangeChart({type, dinamicData}) {
    const chart = useRef(null);
    let color
    let inversed
    let name
    if(type == 'BuybackCapital'){
        inversed = true
        color = '#2C69DF'
        name =    "$ Issued"
    }else if(type == 'RaiseCapital'){
        inversed = false
        color = '#37BCAE'
        name =    "$ Raise"
    }
    let comulativeData = []
    useLayoutEffect(() => {
        let chart = am4core.create("chartdiv1", am4charts.XYChart);
        let data
        if(dinamicData == null){
            data = [
                {
                    "date": "1.May",
                    "value": 227231115.65
                }, {
                    "date": "2.May",
                    "value": 524902261.62
                }, {
                    "date": "1.Jun",
                    "value": 829197873.08
                }, {
                    "date": "2.Jun",
                    "value": 1136536184.09
                }, {
                    "date": "1.Jul",
                    "value": 1402432936.05
                }, {
                    "date": "2.Jul",
                    "value": 1562178543.29
                }, {
                    "date": "1.Aug",
                    "value": 1718384634.58
                }, {
                    "date": "2.Aug",
                    "value": 1864990694.43
                }, {
                    "date": "1.Sep",
                    "value": 2019207049.69
                }, {
                    "date": "2.Sep",
                    "value": 2165812419.28
                }, {
                    "date": "1.Oct",
                    "value": 2316218820.87
                }, {
                    "date": "2.Oct",
                    "value": 2468034029.10
                }, {
                    "date": "1.Nov",
                    "value": 2612740629.11
                }, {
                    "date": "2.Nov",
                    "value": 2763195650.29
                }, {
                    "date": "1.Dec",
                    "value": 2913378382.82
                }, {
                    "date": "2.Dec",
                    "value": 3060812649.57
                }, {
                    "date": "1.Jan",
                    "value": 3214991662.46
                }, {
                    "date": "2.Jan",
                    "value": 3365195060.12
                }, {
                    "date": "1.Feb",
                    "value": 3504518474.06
                },
            ]
        }else{
            for (let i=0; i < dinamicData.length; i++){
                if( i == 0 ){
                    comulativeData.push( dinamicData[i])
                }else{
                    let sumValue = dinamicData[i].value +  comulativeData[i-1].value
                    comulativeData.push( {...dinamicData[i], value: sumValue} )
                }
            }
            data = comulativeData
        }
        chart.data = data
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.lineX.disabled = true;
        chart.cursor.lineY.disabled = true;

        // x-axios

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "date";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.renderer.labels.template.fill = am4core.color("#B3B6C1");
        categoryAxis.renderer.grid.template.strokeDasharray = 4;
        categoryAxis.renderer.line.strokeOpacity = 1;
        categoryAxis.renderer.line.strokeWidth = 2;
        categoryAxis.renderer.line.stroke = am4core.color("#D5DDF1");
        categoryAxis.renderer.background.fill = am4core.color("#F8FAFE");
        categoryAxis.renderer.marginTop = 20;
        //title

        var title = chart.titles.create();
        title.text =  name;
        title.fontSize = 13;
        title.fill = am4core.color('#A7ACC1');
        title.marginBottom = 30;
        title.align = 'left'
        categoryAxis.cursorTooltipEnabled = false;

        // y-axios
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.renderer.opposite = true;
        valueAxis.renderer.labels.template.fill = am4core.color("#B3B6C1");
        valueAxis.strictMinMax = true;
        valueAxis.renderer.minGridDistance = 60;
        valueAxis.renderer.inversed = inversed
        valueAxis.renderer.labels.template.fontSize = 11;
        valueAxis.renderer.grid.template.strokeDasharray = 4;
        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.strokeWidth = 2;
        valueAxis.renderer.line.stroke = am4core.color("#D5DDF1");
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.renderer.labels.template.adapter.add("text", (label, target, key) => {
            return label ? '$' + label : null
        });

        // Create series
        var series = chart.series.push(new am4charts.LineSeries());

        series.dataFields.valueY = "value";
        series.dataFields.categoryX = "date";
        series.clustered = false;
        series.tooltipText = '{categoryX}  ' +'\n' + " [ "+color +" ]●[/] " + 'BuybackCapital: ' + '{value}'
        series.tooltip.getFillFromObject = false;
        series.tooltip.getStrokeFromObject = false;
        series.tooltip.background.stroke = am4core.color("#000");
        series.tooltip.background.fill = am4core.color("#000");
        series.sequencedInterpolation = true;
        series.fillOpacity = 0.3;
        series.strokeWidth = 2;
        series.stacked = true;
        series.stroke = am4core.color(color);
        series.fill = am4core.color(color);

        //bullet
        let circleBullet = series.bullets.push(new am4charts.CircleBullet());
    }, [type, dinamicData]);
    return (
        <div id="chartdiv1" style={{width: "100%", height: "500px"}}></div>
    );
}
const ChartCumulativeCapitalChange = ({type, dinamicData = null, children}) => {

    return <>
        <div className="wrapper-chart">
            <div className="chart-img">
                {children}
                <div className="chart-img">
                    <CamulativeCapitalChangeChart type={type} dinamicData={dinamicData}/>
                </div>
            </div>

        </div>
    </>
}
export default ChartCumulativeCapitalChange

