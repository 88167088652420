import React from 'react';
import {setUrl} from "../../store/configureStore";
import {Link} from "react-router-dom";
import SignUpForm from "./SignUpForm";


const SignUp = () => {


    return<>
       <div className="wrapper-signup">
           <div className={"holder flex-layout"}>
               <strong className="logo">
                       <img src={setUrl("/images/logo.svg")} alt="skrip"/>
               </strong>
               <Link className='btn-white' to={setUrl('/login')}>Log in</Link>
               <div className="signup-block">
                   <SignUpForm />
               </div>
           </div>
       </div>
    </>
}
export default SignUp

