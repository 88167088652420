import React, {useContext} from 'react';
import {setUrl} from "../../store/configureStore";
import {Link} from "react-router-dom";
import {AuthContext} from "../App";

const SignUpForm = () => {
    return <>
        <div className="wrapper-signup-form">
            <h1><span>Sign Up</span> for future updates</h1>
            <p>To register your interest in the platform ahead of our launch, please enter your details below</p>
            <form action={"#"}>
                <div className={'row'}>
                    <input id="first-name" name="first-name" placeholder={'First name'} type='text'/>
                    <input id="last-name" name="last-name" placeholder={'Last name'} type='text'/>
                </div>
                <div className={'row'}>
                    <input id="company" name="company" placeholder={'Company'} type='text'/>
                    <input id="email" name="email" placeholder={'Email address'} type='email'/>
                </div>
                <button type="submit">Submit</button>
            </form>
        </div>
    </>
}
export default SignUpForm

