import React
    from 'react';
import ChartMarketEquityPerformance
    from "../../GraphsCharts/ChartMarketEquityPerformance/ChartMarketEquityPerformance";
import ChangeStrategy
    from "../ChangeStrategy/ChangeStrategy";
import ChartMarginOverClosePrice
    from "../../GraphsCharts/ChartMarginOverClosePrice/ChartMarginOverClosePrice";
import ChartExecutionVenue
    from "../../GraphsCharts/ChartExecutionVenue/ChartExecutionVenue";
import ChartExecutionBroke
    from "../../GraphsCharts/ChartExecutionBroke/ChartExecutionBroker";

const Execution = () => {

    return <>
        <ChangeStrategy/>
        <ChartMarketEquityPerformance/>
        <ChartMarginOverClosePrice/>
        <div className="flex-layout">
            <ChartExecutionBroke/>
            `<ChartExecutionVenue/>
        </div>

    </>
}
export default Execution

