import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import createSagaMiddleware from 'redux-saga'

import {gameReducer} from "../reducers/game";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true,
    traceLimit: 25
})) || compose;

export const store = createStore(combineReducers({
        gameData: ((data ={})=>data),
    }),
    composeEnhancers(applyMiddleware(sagaMiddleware))
);


// sagaMiddleware.run(sagasCreator);

const BaseUrl = ''
 //const BaseUrl = '/'
 // const BaseUrl2 = '/'

 const BaseUrl2 = '/'


export  let setUrl = (url)=> {
    return BaseUrl + url
}

export  let setUrl2 = (url)=> {
    return BaseUrl2 + url
}

