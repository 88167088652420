import React, {useState} from 'react';
import CountUp from "react-countup";


const OneCapital = ({id, title, num, remaining, income, category, status, prefix, suffix}) => {

    return <>
        <div className={`${category} ${status} wrapper-col-category`}>
            <div className="col-category">
                <span className='icon-category'>icon-category</span>
                <div className="info-text">
                    <h5>{title}</h5>
                    <strong className='price'>
                        <CountUp
                            start={0}
                            end={num}
                            duration={2.75}
                            separator=","
                            decimals={2}
                            decimal="."
                            prefix={prefix}
                            suffix={suffix}>
                        </CountUp>
                    </strong>
                    {remaining.length > 0 && <span className='remaining'>{remaining}</span>}
                </div>
                <span className='income'>{income}</span>
            </div>
        </div>
    </>
}
export default OneCapital

