import React, {useContext} from 'react';
import {setUrl2} from "../../store/configureStore";
import {Link} from "react-router-dom";
import SliderLogin from "../SliderLogin/SliderLogin";
import {Field, Form, Formik} from "formik";
import {AuthContext} from "../App";

let auth2 ={
    user: 'dev',
    password: 'dev!23'
}
function validateUsername(value) {
    let error;
    if ((value !== 'dev') ){
        error = 'Nice try!';
    }
    return error;
}
function validatePassword(value) {
    let error;
    if ((value !== 'dev!23') ){
        error = 'Nice try!';
    }
    return error;
}
const LoginForm = () => {
    const { auth, setAuth } = useContext(AuthContext);
    return <>
        <div className="wrapper-login-form">
            <h1>Log in to Skrip</h1>
            <Formik
                initialValues={{
                    user: '',
                    password: ''
                }}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, errors, isSubmitting) => {
                   if( JSON.stringify(auth2) == JSON.stringify(values)){
                       setAuth(true)
                   }

                    // await new Promise((r) => setTimeout(r, 500));
                    // alert(JSON.stringify(values, null, 2));
                }}
            >
                {({ errors, touched, validateField, validateForm, isSubmitting }) => (

                <Form>

                    <label htmlFor="user">Email or mobile number</label>
                    <Field id="user" name="user" type='text' validate={validateUsername} />
                    <div className="wrapper-password">
                        <Link className='forgot-password' to='#'>Forgot Password?</Link>
                        <label htmlFor="password">Password</label>
                        <Field id="password" name="password" type="password" validate={validatePassword}/>
                    </div>
                    {((errors.user || errors.password) && !isSubmitting ) && <div className='error'> Incorrect login or password</div>}
                    <button   type="submit">Log in</button>
                </Form>
                )}
            </Formik>
            <div className="info">
                <p>Don't have an account? </p><Link className='create-account' to=''>Create Account</Link>
            </div>
        </div>
    </>
}
export default LoginForm

