import React from 'react';
import {setUrl} from "../../store/configureStore";
import {Link} from "react-router-dom";
import SliderLogin from "../SliderLogin/SliderLogin";
import LoginForm from "./LoginForm";


const Login = () => {


    return<>
       <div className="wrapper-login flex-layout">
           <strong className="logo">
                   <img src={setUrl("/images/logo-dark.svg")} alt="skrip"/>
           </strong>
           <Link className='sing-up-btn' to={setUrl('/')}>Sign up </Link>
           <div className="login-block col">
               <LoginForm />
               <div className="wrapper-link">
                   <Link to='#'>Legal & Privacy</Link>
                   <Link to='#'>Help Centre</Link>
               </div>
           </div>
           <div className="slider-block col">
               <SliderLogin/>

           </div>
       </div>
    </>
}
export default Login

