import React, {useState} from 'react';


const SavedStrategyPopup = (props) => {


    return <>
        {props.children}
    </>
}
export default SavedStrategyPopup

