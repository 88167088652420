import React from 'react';


const EquityTrackingPage = () => {



    return<>
        <h1> Equity Tracking</h1>
    </>
}
export default EquityTrackingPage

