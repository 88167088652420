import React, {useContext} from "react";
import {Link, NavLink} from "react-router-dom";
import {setUrl2} from "../../store/configureStore";
import {setUrl} from "../../store/configureStore";
import {AuthContext} from "../App";


const Sidebar = ({hideMenu, setHideMenu}) => {
    const {auth, setAuth} = useContext(AuthContext);
    const toggleMenu = () => {
        setHideMenu(!hideMenu)
    }
    return <>
        <aside>
            <span className='hide-menu' onClick={toggleMenu}>hide menu</span>
            <strong className="logo">
                <a href="#">
                    <img src={setUrl("/images/logo.svg")} alt="skrip"/>
                </a>
            </strong>
            <nav>
                <NavLink exact activeClassName="active" className='strategy-overview' to={setUrl('/strategy-overview')}>Strategy Overview</NavLink>
                <NavLink exact activeClassName="active" className='new-strategy' to={setUrl('/new-strategy')}>Create New Strategy</NavLink>
                <NavLink exact activeClassName="active" className='saved-strategy' to={setUrl('/saved-strategy')}>Saved Strategies</NavLink>
                <NavLink exact activeClassName="active" className='settlements' to={setUrl('/settlements')}>Settlements</NavLink>
                <NavLink exact activeClassName="active" className='equity-tracking' to={setUrl('/equity-tracking')}>Equity Tracking</NavLink>
                <NavLink exact activeClassName="active" className='reporting' to={setUrl('/reporting')}>Reporting</NavLink>
                <NavLink exact activeClassName="active" className='help' to={setUrl('/help')}>Help</NavLink>
            </nav>
            <Link className='log-out-btn' onClick={() => setAuth(false)} to={setUrl('/')}>Log out</Link>
        </aside>

    </>
}

export default Sidebar
