import React, {
    useContext,
    useState
} from 'react';
import Sidebar
    from "../Sidebar/Sidebar";
import {
    useHistory,
    Route,
    Switch
} from "react-router-dom";
import {setUrl} from "../../store/configureStore";
import NewStrategyPage
    from "../NewStrategyPage/NewStrategyPage";
import SavedStrategyPage
    from "../SavedStrategyPage/SavedStrategyPage";
import SettlementsPage
    from "../SettlementsPage/SettlementsPage";
import EquityTrackingPage
    from "../EquityTrackingPage/EquityTrackingPage";
import ReportingPage
    from "../ReportingPage/ReportingPage";
import HelpPage
    from "../HelpPage/HelpPage";
import StrategyOverviewPage
    from "../StrategyOverviewPage/StrategyOverviewPage";
import Login
    from "../Login/Login";
import SavedStrategyPopup
    from "../Popup/SavedStrategyPopup";
import usePortal  from '../usePortal';
import {createPortal} from "react-dom";
import EditStrategyPage from "../EditStrategyPage/EditStrategyPage";


const FullContent = ({setAuth}) => {
    let [hideMenu, setHideMenu] = useState(false)
    let [yes, setYes] = useState(false)
    let [closePopup, setClosePopup] = useState(false)
    let [strategyOverviewHits, setStrategyOverviewHits] = useState(false)
    let [ExecutionPopup, setExecutionPopup] = useState(false)
    let [SavePopup, setSavePopup] = useState(false)
    let [deletePopup, setDeletePopup] = useState(false)
    const [cumulativeData, setCumulativeData] = useState();
    const [capitalData, setCapitalData] = useState();
    const [idStrategy, setIdStrategy] = useState();
    const [nameStrategy, setnameStrategy2] = useState('');

    const target = usePortal('modal-popup');
    return <>

        <div className={hideMenu ? "container full-width-container" : "container"}>
            <Sidebar hideMenu={hideMenu} setHideMenu={setHideMenu} setAuth={setAuth}/>
            <div className="content">
                <Switch>
                    <Route exact path={setUrl('/strategy-overview')} render={() =>
                        <StrategyOverviewPage setClosePopup={setClosePopup} strategyOverviewHits={strategyOverviewHits} setStrategyOverviewHits={setStrategyOverviewHits}/>}/>
                    <Route exact path={setUrl('/new-strategy')} render={() =>
                        <NewStrategyPage setClosePopup={setClosePopup}
                                         setExecutionPopup={setExecutionPopup}
                                         setSavePopup={setSavePopup}
                                         cumulativeData={cumulativeData}
                                         setCumulativeData={ setCumulativeData}
                                         capitalData={capitalData}
                                         setCapitalData={setCapitalData}
                                         setnameStrategy2={setnameStrategy2}/>}/>
                    <Route exact path={setUrl('/saved-strategy')} render={() =>
                        <SavedStrategyPage setClosePopup={setClosePopup}
                                           setExecutionPopup={setExecutionPopup}
                                           setSavePopup={setSavePopup}
                                           deletePopup={deletePopup}
                                           setDeletePopup={setDeletePopup}
                                           setCumulativeData={ setCumulativeData}
                                           setCapitalData={setCapitalData}
                                          setIdStrategy={setIdStrategy}/>}/>
                    <Route exact path={setUrl('/settlements')} component={SettlementsPage}/>
                    <Route exact path={setUrl('/equity-tracking')} component={EquityTrackingPage}/>
                    <Route exact path={setUrl('/reporting')} component={ReportingPage}/>
                    <Route exact path={setUrl('/help')} component={HelpPage}/>
                    <Route exact path={setUrl('/edit')} render={() =>
                        <EditStrategyPage setClosePopup={setClosePopup}
                                          setExecutionPopup={setExecutionPopup}
                                         setSavePopup={setSavePopup}
                                         cumulativeData={cumulativeData}
                                         setCumulativeData={ setCumulativeData}
                                         capitalData={capitalData}
                                         setCapitalData={setCapitalData}
                                          setIdStrategy={setIdStrategy}
                                          idStrategy={idStrategy}
                                          setnameStrategy2={setnameStrategy2}
                        />}/>
                </Switch>
            </div>

        </div>

        {closePopup &&
        createPortal(
            <div className="popup-overlay">
                <div className="wrapper-popup">
                    {strategyOverviewHits &&
                    <SavedStrategyPopup>
                        <>
                           <span onClick={() => {
                               setClosePopup(false)
                               setStrategyOverviewHits(false)
                               setYes(false)
                           }} className="close-popup"></span>
                            {!yes ? <>

                                <h2>Are you sure you want to halt "Strategy Name" ? </h2>
                                <button className='yes-btn bg-none' onClick={() => {
                                    setYes(true)
                                }}>Yes
                                </button>
                                <button onClick={() => {
                                    setClosePopup(false)
                                    setStrategyOverviewHits(false)
                                }} className='no-btn bg-full'>No
                                </button>
                            </> : <>
                                <h2>"Strategy Name" has been halted</h2>
                                <button onClick={() => {
                                    setClosePopup(false)
                                    setStrategyOverviewHits(false)
                                    setYes(false)
                                }} className='ok-btn '>Ok
                                </button>
                            </>

                            }
                        </>
                    </SavedStrategyPopup>
                    }
                    {ExecutionPopup &&
                    <SavedStrategyPopup>
                        <>
                        <span onClick={() => {
                            setClosePopup(false);
                            setDeletePopup(false)
                            setYes(false)
                            setExecutionPopup(false)
                        }} className="close-popup"></span>
                            {!yes ? <>
                                <h2> Are you sure you want to activate "Strategy Name" ? </h2>
                                <button className='yes-btn bg-none' onClick={() => {
                                    setYes(true)
                                }}>Yes
                                </button>
                                <button onClick={() => {
                                    setClosePopup(false)
                                    setExecutionPopup(false)
                                }} className='no-btn bg-full'>No
                                </button>
                            </> : <>
                                <h2>"Strategy Name" has been activated</h2>
                                <button onClick={() => {
                                    setClosePopup(false)
                                    setExecutionPopup(false)
                                    setYes(false)
                                }} className='ok-btn'>Ok
                                </button>
                            </>
                            }
                        </>
                    </SavedStrategyPopup>
                    }
                    {SavePopup &&
                    <SavedStrategyPopup>
                        <>
                        <span onClick={() => {
                            setClosePopup(false);
                            setDeletePopup(false)
                            setYes(false)
                            setSavePopup(false)
                        }} className="close-popup"></span>
                            <h2>"{nameStrategy}" has been saved</h2>
                            <button onClick={() => {
                                setClosePopup(false)
                                setSavePopup(false)
                                setYes(false)
                            }} className='ok-btn'>Ok
                            </button>
                        </>
                    </SavedStrategyPopup>
                    }
                    {deletePopup &&
                    <SavedStrategyPopup>
                        <>
                          <span onClick={() => {
                              setClosePopup(false);
                              setDeletePopup(false)
                              setYes(false)
                          }} className="close-popup"></span>
                            {!yes ? <>
                                <h2> Are you sure you want to delete "Strategy Name" ? </h2>
                                <button className='yes-btn bg-none' onClick={() => {
                                    setYes(true)
                                }}>Yes
                                </button>
                                <button onClick={() => {
                                    setClosePopup(false)
                                    setDeletePopup(false)
                                }} className='no-btn bg-full'>No
                                </button>
                            </> : <>
                                <h2>"Strategy Name" has been deleted</h2>
                                <button onClick={() => {
                                    setClosePopup(false)
                                    setDeletePopup(false)
                                    setYes(false)
                                }} className='ok-btn'>Ok
                                </button>
                            </>
                            }
                        </>
                    </SavedStrategyPopup>
                    }
                </div>
            </div>,
           target
        )

        }
    </>

}
export default FullContent

