import React from 'react';


const ReportingPage = () => {



    return<>
        <h1> Reporting</h1>
    </>
}
export default ReportingPage

