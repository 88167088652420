import React, {
    Component, useLayoutEffect, useRef,
    useState
} from 'react';


import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

function CamulativeCapitalChangeChartPlan({type, dinamicData, setCumulativeData}) {
    // console.log(dinamicData)
    const chart = useRef(null);
    let color
    let inversed
    let name
    if(type == 'BuybackCapital'){
        inversed = true
        color = '#2C69DF'
        name =    "$ Issued"
    }else if(type == 'RaiseCapital'){

        inversed = false
        color = '#37BCAE'
        name =    "$ Raise"
    }

    let comulativeData = []

    useLayoutEffect(() => {
        let chart = am4core.create("chartdiv1", am4charts.XYChart);
        let data

        if(dinamicData !== null){
            for (let i=0; i < dinamicData.length; i++){

                if( i == 0 ){
                    comulativeData.push( dinamicData[i])
                }else{
                    let sumValue = dinamicData[i].value +  comulativeData[i-1].value
                    comulativeData.push( {...dinamicData[i], value: sumValue} )
                }

            }
            data = comulativeData
            setCumulativeData(data)
        }
        chart.data = data
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.lineX.disabled = true;
        chart.cursor.lineY.disabled = true;
        chart.numberFormatter.numberFormat = "###,###,###,###,###."
        // x-axios

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "date";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.renderer.labels.template.fill = am4core.color("#B3B6C1");
        categoryAxis.renderer.grid.template.strokeDasharray = 4;
        categoryAxis.renderer.line.strokeOpacity = 1;
        categoryAxis.renderer.line.strokeWidth = 2;
        categoryAxis.renderer.line.stroke = am4core.color("#D5DDF1");
        categoryAxis.renderer.background.fill = am4core.color("#F8FAFE");
        categoryAxis.renderer.marginTop = 20;
        //title

        var title = chart.titles.create();
        title.text =  name;
        title.fontSize = 13;
        title.fill = am4core.color('#A7ACC1');
        title.marginBottom = 30;
        title.align = 'left'
        categoryAxis.cursorTooltipEnabled = false;

        // y-axios
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
     // valueAxis.max = data.chart[data.chart.lenght -1] + 10000;
        valueAxis.renderer.opposite = true;
        valueAxis.renderer.labels.template.fill = am4core.color("#B3B6C1");
        valueAxis.strictMinMax = true;
        valueAxis.renderer.minGridDistance = 60;
        valueAxis.renderer.inversed = inversed
        valueAxis.renderer.labels.template.fontSize = 11;
        valueAxis.renderer.grid.template.strokeDasharray = 4;
        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.strokeWidth = 2;
        valueAxis.renderer.line.stroke = am4core.color("#D5DDF1");
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.renderer.labels.template.adapter.add("text", (label, target, key) => {
            // return label ? '$' + label.split(',')[0] + 'b' : null
            return label ? '$' + label : null
        });

        // Create series
        var series = chart.series.push(new am4charts.LineSeries());

        series.dataFields.valueY = "value";
        series.dataFields.categoryX = "date";
        series.clustered = false;
        // series.tooltipHTML = "<img src='https://www.amcharts.com/lib/3/images/car.png' style='vertical-align:bottom; margin-right: 10px; width:28px; height:21px;'><span style='font-size:14px; color:#000000;'><b>{valueY.value}</b></span>";
        series.tooltipText = '{categoryX}  ' +'\n' + " [ "+color +" ]●[/] " + 'BuybackCapital: ' + '{value}'
        series.tooltip.getFillFromObject = false;
        series.tooltip.getStrokeFromObject = false;
        series.tooltip.background.stroke = am4core.color("#000");
        series.tooltip.background.fill = am4core.color("#000");
        // series.tooltip.color.fill = am4core.color("#000");
        series.sequencedInterpolation = true;
        series.fillOpacity = 0.3;
        series.strokeWidth = 2;
        series.stacked = true;
        series.stroke = am4core.color(color);
        series.fill = am4core.color(color);

        //bullet
        let circleBullet = series.bullets.push(new am4charts.CircleBullet());


    }, [type, dinamicData]);
    return (
        <div id="chartdiv1" style={{width: "100%", height: "500px"}}></div>
    );
}
const ChartCumulativeCapitalChangePlan = ({type, dinamicData = null, setCumulativeData, children}) => {

    return <>
        <div className="wrapper-chart">
            <div className="chart-img">
                {children}
                <div className="chart-img">
                    <CamulativeCapitalChangeChartPlan type={type} dinamicData={dinamicData} setCumulativeData={setCumulativeData}/>
                </div>

                {/*<img src={setUrl2("images/chart-cumulative-capital-change.png")} alt=""/>*/}
            </div>

        </div>
    </>
}
export default ChartCumulativeCapitalChangePlan

