import React, {
    Component, useEffect, useLayoutEffect, useRef,
    useState
} from 'react';
import readXlsxFile from "read-excel-file";
import {setUrl2} from "../../../store/configureStore";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);


function MarginOverClosePriceChart(props) {
    const chart = useRef(null);


    let [data, setData] = useState()

    useLayoutEffect(() => {
        let chart = am4core.create("chartdiv3", am4charts.XYChart);
        const map = {
            'date': 'Date',
            'value1': 'Cum. Weighted Margin',
            'value2': 'VWTP Margin to Close',

        }

        const schema = {
            'Date': {
                // JSON object property name.
                prop: 'date',
                type: Date
            },
            'Cum. Weighted Margin': {
                // JSON object property name.
                prop: 'value1',
                type: Number
            },
            'VWTP Margin to Close': {
                // JSON object property name.
                prop: 'value2',
                type: Number
            }
        }

        fetch("/skripApp/public/data.xlsx")
            .then(response => response.arrayBuffer())
            .then(text => {

                readXlsxFile(text, {sheet: 'Trade Performance Graph', map, schema}).then((data2) => {

                    chart.data = data2.rows;
// Create axes
                    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                    dateAxis.renderer.minGridDistance = 60;
//change xaxios lable
              //dateAxis.renderer.grid.template.location = 1;
                    //  dateAxis.renderer.minGridDistance = 30;
                    dateAxis.renderer.labels.template.fill = am4core.color("#B3B6C1");
                    dateAxis.renderer.grid.template.strokeWidth = 0;
                    dateAxis.renderer.line.strokeOpacity = 1;
                    dateAxis.renderer.line.strokeWidth = 2;
                    dateAxis.renderer.line.stroke = am4core.color("#D5DDF1");
                    dateAxis.renderer.background.fill = am4core.color("#F8FAFE");
                    dateAxis.renderer.marginTop = 20;
                    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                    // valueAxis.min = 0;
                    // valueAxis.max = 360000000;
                    valueAxis.renderer.opposite = true;
                    valueAxis.renderer.labels.template.fill = am4core.color("#B3B6C1");
                    //valueAxis.strictMinMax = true;
                    valueAxis.renderer.minGridDistance = 80;
                    valueAxis.renderer.line.strokeOpacity = 1;
                    valueAxis.renderer.line.strokeWidth = 2;
                    valueAxis.renderer.line.stroke = am4core.color("#D5DDF1");
                    valueAxis.renderer.labels.template.fontSize = 11;
                    valueAxis.renderer.grid.template.strokeDasharray = 4;
                    valueAxis.title.fontWeight = 800;

                    //valueAxis.cursorTooltipEnabled = false;
                    valueAxis.renderer.labels.template.adapter.add("text", (label, target, key) => {
                        return label ? '$' + label.split(',')[0] + 'm' : null
                    });


                    let series1 = chart.series.push(new am4charts.ColumnSeries());
                    let color2
                    series1.dataFields.valueY = "value2";
                    series1.dataFields.dateX = "date";
                    series1.name = "series1";
                    series1.tooltipText = "{name}\n[bold font-size: 20]${valueY}M[/]";
                    series1.fill = chart.colors.getIndex(0);
                    series1.strokeWidth = 0;
                    series1.clustered = false;
                    series1.columns.template.width = am4core.percent(10);
                    series1.hiddenInLegend = true;
                    series1.tooltipText = '{categoryX} ' + "[ " + color2  + " ]●[/] " + 'Raised ' + '{valueY}'
                    series1.tooltip.getFillFromObject = false;
                    series1.tooltip.getStrokeFromObject = false;
                    series1.tooltip.background.stroke = am4core.color("#000");
                    series1.tooltip.background.fill = am4core.color("#000");
                    // series1.columns.template.adapter.add("tooltipText", function (fill, target) {
                    //     console.log(target.dataItem.valueY )
                    //     if (target.dataItem && (target.dataItem.valueY < 0)) {
                    //         let text ='{dateX} ' + "[ " + 'item.fill.hex' + " ]●[/] " + 'Raised ' + '{valueY}'
                    //         return  text
                    //     } else {
                    //         return  '{categoryX} ' + "[ " + '#37BCAE' + " ]●[/] " + 'Raised ' + '{valueY}'
                    //     }
                    // });
                    series1.columns.template.adapter.add("fill", function (value, target, key) {
                        if (target.dataItem && (target.dataItem.valueY > 0)) {
                            color2 = '#37BCAE'
                            return am4core.color(  color2);
                        } else  {
                            color2 = '#EB5757'
                            return am4core.color(color2);
                        }
                    });

                    function createSeries(value, name, color) {
                        let series = chart.series.push(new am4charts.LineSeries());
                        series.dataFields.valueY = value;
                        series.dataFields.dateX = "date";
                        series.name = name
                        series.tooltipText = '{categoryX} ' + "[ " + color + " ]●[/] " + 'Raised ' + '{valueY}'
                        series.tooltip.getFillFromObject = false;
                        series.tooltip.getStrokeFromObject = false;
                        series.tooltip.background.stroke = am4core.color("#000");
                        series.tooltip.background.fill = am4core.color("#000");

                        series.fillOpacity = 0;
                        series.strokeWidth = 2;
                        //series.stacked = true;
                        series.stroke = am4core.color(color);
                        series.fill = am4core.color(color);

                        let bullet = series.bullets.push(new am4charts.CircleBullet());
                        bullet.circle.stroke = am4core.color("#fff");
                        bullet.circle.strokeWidth = 2;


                        let range = valueAxis.createSeriesRange(series);
                        range.value = 0;
                        range.endValue = -1000;
                        range.contents.stroke = am4core.color(color);
                        range.contents.fill = am4core.color(color);
                        return series;
                    }

                    createSeries('value1', 'name', '#37BCAE')
                    chart.cursor = new am4charts.XYCursor();
                    // chart.cursor.lineX.disabled = true;
                    // chart.cursor.lineY.disabled = true;
                 chart.cursor.maxTooltipDistance = -1;
                })
            })

    }, []);
    return (
        <div id="chartdiv3" style={{width: "100%", height: "500px"}}></div>
    );
}

const ChartMarginOverClosePrice = () => {

    return <>
        <div className="wrapper-chart chart-market-equity-performance">
            <div className="top-row">
                <h3>VWAP Margin over Close Price</h3>
            </div>
            <div className="chart-img">
                <MarginOverClosePriceChart/>
                {/*<img src={setUrl2("images/chart-margin-over-close-price.png")} alt=""/>*/}
            </div>
        </div>
    </>
}
export default ChartMarginOverClosePrice

