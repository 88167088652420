import React from 'react';
import CapitalInfo from "../../GraphsCharts/CapitalInfo";
import ChartExecutionAgainstPlan
    from "../../GraphsCharts/ChartExecutionAgainstPlan/ChartExecutionAgainstPlan";
import ChartCumulativeCapitalChange
    from "../../GraphsCharts/ChartCumulativeCapitalChange/ChartCumulativeCapitalChange";
import ChartMarketEquityPerformance
    from "../../GraphsCharts/ChartMarketEquityPerformance/ChartMarketEquityPerformance";
import Select from 'react-select';

export const customStyles = {
    option: (provided, state) => ({
        ...provided,
        background: '#fff',
        color: state.isSelected ? '#37BCAE' : '#58595B',

    }),
    menu: (provided, state) => ({
        ...provided,
        width: state.selectProps.width,
        border: '1px solid #D6E2EC',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 15,
        paddingRight: 15,
        borderRadius: 15,
        boxShadow: '0px 14px 25px rgba(14, 23, 69, 0.1)'
    }),
    control: (_, {selectProps: {width}}) => ({
        width: width
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return {...provided, opacity, transition};
    }
}
const ChangeStrategy = () => {
    let options = [
        {value: 'Net Trading Strategy', label: 'Net Trading Strategy'},
        {value: 'Net Trading Strategy2', label: 'Net Trading Strategy2'},
        {value: 'Net Trading Strategy3', label: 'Net Trading Strategy3'}
    ]
    let options2 = [
        {value: 'FY20', label: 'FY20'},
        {value: 'FY201', label: 'FY201'},
        {value: 'FY202', label: 'FY202'},

    ]
    console.log(options[0].value)
    const handleChange = (value) => {
        // console.log(e.target.value)
        console.log(value)
    }
    return <>
        <div className="row-select">
            <Select

                defaultValue={options[0].value}
                placeholder={options[0].value}
                options={options}
                name='choose-strategy'
                onChange={(values) => handleChange(values)}
                className='choose-strategy select'
                styles={customStyles}
                width='inherit'
            />


            <div className="col-financial-year">
                <label htmlFor="">Financial Year</label>
                <Select
                    defaultValue={options2[0].value}
                    placeholder={options2[0].value}
                    options={options2}
                    name='select-chart-data'
                    onChange={(values) => handleChange(values)}
                    className='select-chart-data select'
                    styles={customStyles}
                    width='inherit'
                />

            </div>
        </div>

    </>
}
export default ChangeStrategy

