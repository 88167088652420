import React, {
    Component, useLayoutEffect, useRef,
    useState
} from 'react';

import Chart
    from "react-apexcharts";
import {setUrl2} from "../../../store/configureStore";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

function CamulativeCapitalChangeChartOverveiw({type, dinamicData}) {
    const chart = useRef(null);
    let color
    let color2
    let inversed
    let name
    if (type == 'BuybackCapital') {
        inversed = true
        color = '#2C69DF'
        name = "$ Issued"
    } else if (type == 'RaiseCapital') {
        inversed = false
        color = '#37BCAE'
        color2 = '#9ae5d9'
        name = "$ Raise"
    }
    let comulativeData = []
    useLayoutEffect(() => {
        let chart = am4core.create("chartdiv1", am4charts.XYChart);
        let data
        if (dinamicData == null) {
            data = [
                {
                    "date": "1.May",
                    "value": 227231115.65,
                    "planned": 220000000.00
                }, {
                    "date": "2.May",
                    "value": 524902261.62,
                    "planned": 515000000.00
                }, {
                    "date": "1.Jun",
                    "value": 829197873.08,
                    "planned": 810000000.00
                }, {
                    "date": "2.Jun",
                    "value": 1136536184.09,
                    "planned": 1105000000.00
                }, {
                    "date": "1.Jul",
                    "value": 1402432936.05,
                    "planned": 1400000000.00
                }, {
                    "date": "2.Jul",
                    "value": 1562178543.29,
                    "planned": 1550000000.00
                }, {
                    "date": "1.Aug",
                    "value": 1718384634.58,
                    "planned": 1700000000.00
                }, {
                    "date": "2.Aug",
                    "value": 1864990694.43,
                    "planned": 1850000000.00
                }, {
                    "date": "1.Sep",
                    "value": 2019207049.69,
                    "planned": 2000000000.00
                }, {
                    "date": "2.Sep",
                    "value": 2165812419.28,
                    "planned": 2150000000.00
                }, {
                    "date": "1.Oct",
                    "value": 2316218820.87,
                    "planned": 2300000000.00
                }, {
                    "date": "2.Oct",
                    "value": 2468034029.10,
                    "planned": 2450000000.00
                }, {
                    "date": "1.Nov",
                    "value": 2612740629.11,
                    "planned": 2600000000.00
                }, {
                    "date": "2.Nov",
                    "value": 2763195650.29,
                    "planned": 2750000000.00
                }, {
                    "date": "1.Dec",
                    "value": 2913378382.82,
                    "planned": 2900000000.00
                }, {
                    "date": "2.Dec",
                    "value": 3060812649.57,
                    "planned": 3050000000.00
                }, {
                    "date": "1.Jan",
                    "value": 3214991662.46,
                    "planned": 3200000000.00
                }, {
                    "date": "2.Jan",
                    "value": 3365195060.12,
                    "planned": 3350000000.00
                }, {
                    "date": "1.Feb",
                    "value": 3504518474.06,
                    "planned": 3500000000.00
                }, {
                    "date": "2.Feb",
                    "planned": 3650000000.00
                }, {
                    "date": "1.Mar",

                    "planned": 3800000000.00
                }, {
                    "date": "2.Mar",

                    "planned": 3950000000.00
                }, {
                    "date": "1.Apr",

                    "planned": 4100000000.00
                }, {
                    "date": "2.Apr",

                    "planned": 4250000000.00
                },
            ]
        } else {
            for (let i = 0; i < dinamicData.length; i++) {
                if (i == 0) {
                    comulativeData.push(dinamicData[i])
                } else {
                    let sumValue = dinamicData[i].value + comulativeData[i - 1].value
                    comulativeData.push({...dinamicData[i], value: sumValue})
                }
            }
            data = comulativeData
        }
        chart.data = data
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.lineX.disabled = true;
        chart.cursor.lineY.disabled = true;
        chart.cursor.maxTooltipDistance = -1;
        chart.numberFormatter.numberFormat = "###,###,###,###,###."
        // x-axios

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "date";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.renderer.labels.template.location = 0.8;
         categoryAxis.startLocation = 0.5;
         categoryAxis.endLocation = 0.5;
         // categoryAxis.renderer.minLabelPosition = 0.05;
         // categoryAxis.renderer.maxLabelPosition = 0.95;
        categoryAxis.renderer.labels.template.fill = am4core.color("#B3B6C1");
        categoryAxis.renderer.grid.template.strokeDasharray = 4;
        categoryAxis.renderer.line.strokeOpacity = 1;
        categoryAxis.renderer.line.strokeWidth = 2;
        categoryAxis.renderer.line.stroke = am4core.color("#D5DDF1");
        categoryAxis.renderer.background.fill = am4core.color("#F8FAFE");
        categoryAxis.renderer.marginTop = 20;
        //title

        var title = chart.titles.create();
        title.text = name;
        title.fontSize = 13;
        title.fill = am4core.color('#A7ACC1');
        title.marginBottom = 30;
        title.align = 'left'
        categoryAxis.cursorTooltipEnabled = false;

        // y-axios
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.renderer.opposite = true;
        valueAxis.renderer.labels.template.fill = am4core.color("#B3B6C1");
        valueAxis.strictMinMax = true;
        valueAxis.renderer.minGridDistance = 60;
        valueAxis.renderer.inversed = inversed
        valueAxis.renderer.labels.template.fontSize = 11;
        valueAxis.renderer.grid.template.strokeDasharray = 4;
        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.strokeWidth = 2;
        valueAxis.renderer.line.stroke = am4core.color("#D5DDF1");
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.renderer.labels.template.adapter.add("text", (label, target, key) => {
            return label ? '$' + label : null
        });

        // Create series
        function createSeries(value, name, color) {

            var series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.valueY = value;
            series.dataFields.categoryX = "date";
            series.name = name;
            series.clustered = false;
            series.tooltipText = '{categoryX}  ' + '\n' + " [ " + color + " ]●[/] " +name+ ': ' + '{valueY}'
            series.tooltip.getFillFromObject = false;
            series.tooltip.getStrokeFromObject = false;
            series.tooltip.background.stroke = am4core.color("#000");
            series.tooltip.background.fill = am4core.color("#000");
            series.sequencedInterpolation = true;
            series.fillOpacity = 0.3;
            series.strokeWidth = 2;
            // series.stacked = true;
            series.stroke = am4core.color(color);
            series.fill = am4core.color(color);
            let circleBullet = series.bullets.push(new am4charts.CircleBullet());
        }

        createSeries('planned', 'Planned', color2)
        createSeries('value', 'Executed', color)

        chart.legend = new am4charts.Legend();
        chart.legend.paddingRight = 250
        chart.legend.marginTop = -50
        chart.legend.marginBottom = 10
        chart.legend.position = 'top'
        chart.legend.contentAlign = "right";
        chart.legend.useDefaultMarker = true;
        //bullet

    }, [type, dinamicData]);
    return (
        <div id="chartdiv1" style={{width: "100%", height: "500px"}}></div>
    );
}

const ChartCumulativeCapitalChangeOverveiw = ({type, dinamicData = null, children}) => {

    return <>
        <div className="wrapper-chart">
            <div className="chart-img">
                {children}
                <div className="chart-img">
                    <CamulativeCapitalChangeChartOverveiw type={type} dinamicData={dinamicData}/>
                </div>
            </div>

        </div>
    </>
}
export default ChartCumulativeCapitalChangeOverveiw

