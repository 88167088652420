import React from 'react';


const SettlementsPage = () => {



    return<>
        <h1> Settlements</h1>
    </>
}
export default SettlementsPage

