import React, {
    Component, useEffect, useLayoutEffect, useRef,
    useState
} from 'react';
import NumberFormat from 'react-number-format';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


function CapitalSequencingChart({type, data, setCapitalData}) {
    // console.log(data)
    const [version, setVersion] = useState(0);
    const chart = useRef(null);
    let color
    let inversed
    let name
    if (type == 'BuybackCapital') {

        inversed = true
        color = '#2C69DF'
        name = "$ Issued"
    } else if (type == 'RaiseCapital') {
        // alert('ll')
        inversed = false
        color = '#37BCAE'
        name = "$ Raise"
    }

    useLayoutEffect(() => {
        let chart = am4core.create("chartdiv4", am4charts.XYChart);
        chart.numberFormatter.numberFormat = "###,###,###,###,###."
        chart.data = data
        setCapitalData(data)
        // chart.cursor = new am4charts.XYCursor();
        // chart.cursor.lineX.disabled = true;
        // chart.cursor.lineY.disabled = true;
        // x-axios

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "date";

        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.renderer.labels.template.fill = am4core.color("#B3B6C1");
        categoryAxis.renderer.grid.template.strokeWidth = 1
        categoryAxis.renderer.line.strokeOpacity = 1;
        categoryAxis.renderer.line.strokeWidth = 2;
        categoryAxis.renderer.grid.template.strokeDasharray = 4;
        categoryAxis.renderer.line.stroke = am4core.color("#D5DDF1");
        categoryAxis.renderer.background.fill = am4core.color("#F8FAFE");
        categoryAxis.renderer.marginTop = 20;


        //title

        var title = chart.titles.create();
        title.text = name;
        title.fontSize = 13;
        title.fill = am4core.color('#A7ACC1');
        title.marginBottom = 30;
        title.align = 'left'
        categoryAxis.cursorTooltipEnabled = false;

        // y-axios


        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        // valueAxis.max = 360000000;
        valueAxis.renderer.opposite = true;
        valueAxis.renderer.inversed = inversed
        valueAxis.renderer.labels.template.fill = am4core.color("#B3B6C1");
        valueAxis.strictMinMax = true;
        valueAxis.renderer.minGridDistance = 60;
        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.strokeWidth = 2;
        valueAxis.renderer.line.stroke = am4core.color("#D5DDF1");
        valueAxis.renderer.labels.template.fontSize = 11;
        valueAxis.renderer.grid.template.strokeDasharray = 4;
        valueAxis.title.fontWeight = 800;
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.renderer.labels.template.adapter.add("text", (label, target, key) => {
            // if(label >= 1000000000){
            //     return label ? '$' + label.split(',')[0] + 'b' : null
            // }else if(1000000 <= label < 1000000000){
            //     return label ? '$' + label.split(',')[0] + 'm' : null
            // }else{
            return label ? '$' + label : null
            // }

        });

        // Create series


        var series2 = chart.series.push(new am4charts.ColumnSeries());
        series2.dataFields.valueY = "value";
        series2.dataFields.categoryX = "date";
        series2.dataFields.id = "id";
        series2.clustered = false;
        series2.columns.template.width = am4core.percent(50);
        series2.columns.template.strokeWidth = 0;

        series2.tooltip.getFillFromObject = false;
        series2.tooltip.getStrokeFromObject = false;
        series2.tooltip.background.stroke = am4core.color("#000");
        series2.tooltip.background.fill = am4core.color("#000");
        series2.fill = am4core.color(color);
        series2.columns.template.fill = am4core.color(color);

        series2.columns.template.adapter.add("fill", function (fill, target) {
            if (target.dataItem && (target.dataItem.valueY < 100000000)) {
                return am4core.color(color);
            } else if (target.dataItem && (target.dataItem.valueY > 150000000) && (target.dataItem.valueY < 300000000)) {
                return am4core.color(color);
            } else if (target.dataItem && (target.dataItem.valueY > 300000000)) {
                return am4core.color(color);
            }
        });
        series2.tooltip.label.interactionsEnabled = true;
        series2.tooltip.keepTargetHover = true;
        series2.tooltip.pointerOrientation = "vertical";
        series2.columns.template.adapter.add("tooltipHTML", function (ev, col) {
            let text
            chart.series.each(function (item) {
                if (!item.isHidden) {
                    text = `<span style='color: ${item.fill.hex}'>  ●</span> {categoryX} <br/>  Planned :{valueY} <br/>  <input id="textEdit" class="textEdit"  style="color: #000; height: 30px" type='text'/> <br>
                    <center><input class="apply-btn" onclick="window.setInputData(document.getElementById('textEdit').value, {id})" type="button" value="Apply"></center>`;
                }
            });
            return text;
        });

        window.setInputData = (value, id) => {

            console.log(value.replace(/[$,]{1}/g,''))
            console.log(value)
            data[id] = Object.assign({}, data[id], {value: parseInt(value.replace(/[$,]{1}/g,''))})
            setCapitalData(data.slice())
            setVersion(version + 1);
        }

        var columnTemplate = series2.columns.template;
        columnTemplate.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        columnTemplate.fillOpacity = .8;
        columnTemplate.strokeOpacity = 0;
        columnTemplate.fill = am4core.color("#DCF0E9");
        return () => {
            chart.dispose();
        };
    }, [type, data, version]);

    return (
        <div id="chartdiv4" style={{width: "100%", height: "500px"}}></div>
    );
}

const ChartCapitalSequencing = (props) => {
    let amount = 0
    if(props.data){
      amount = props.data.reduce((prev, current)=>{
            return prev + current.value
        },0)
    }

    useEffect(()=>{
        props.setAmountChart(amount)
    }, [amount])
console.log(amount)
    return <>
        <div className="wrapper-chart wrapper-chart-without-fon">
            <div className="top-row">
                <h3>Capital Sequencing</h3>
                {props.amountChart ?<NumberFormat
                    value={Math.round(amount)}
                    className="foo"
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={(value, props) => <span {...props} className='amount-chart'>Total: ${value}</span>}
                /> : null}
            </div>
            <div className="chart-img">
                <CapitalSequencingChart type={props.type} data={props.data} setCapitalData={props.setCapitalData}/>
                {/*<img src={setUrl2("images/chart-capital-sequencing.png")} alt=""/>*/}
            </div>
        </div>
    </>
}
export default ChartCapitalSequencing



function live(eventType, elementId, cb) {
    document.addEventListener(eventType, function (event) {
        if (event.target.className === elementId) {

            cb.call(event.target, event);
        }
    });
}



live("keypress", "textEdit", function (event) {
    // console.log(this);
    const formatter = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD",maximumFractionDigits : 0});


    setTimeout(()=>{
        const val = formatter.format(this.value.replace(/[$,]{1}/g,''));
        this.value = val;
        //document.getElementById('textEdit').value = formatter.format('886654889')
    },20);

});





