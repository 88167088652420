import React from 'react';
import {setUrl2} from "../../store/configureStore";


const Auth = () => {
console.log(setUrl2)


    return<>
        <div className="wrapper-auth">
            <div className="person-info">
                <strong className='name'>Nicholas Allan</strong>
                <span className='working-place'>Example Large Co</span>
            </div>
            <div className="auth-img">
                <img src={setUrl2("images/auth-img.png" )}alt="auth-img"/>
            </div>
            <span className="settings"></span>
        </div>

    </>
}
export default Auth

