import React from 'react';


const RowTableTitle = () => {


    return <>
        <div className="top-row-title">
            <div className="col name"><span className='chosen'></span> Name</div>
            <div className="col status">Status</div>
            <div className="col amount">Amount</div>
            <div className="col date">Date</div>
            <div className="col action">Action</div>
        </div>
    </>
}
export default RowTableTitle

