import React from 'react';
import StrategyList from "./StrategyList/StrategyList";
import NewStrategyPage from "../NewStrategyPage/NewStrategyPage";



const SavedStrategyPage = ({setClosePopup, setExecutionPopup, setSavePopup, deletePopup, setDeletePopup  , setCumulativeData,

                               setCapitalData, setIdStrategy}) => {

    return <>
        <h1>Saved Strategies</h1>
        <StrategyList setClosePopup={setClosePopup} setExecutionPopup={setExecutionPopup} setSavePopup={setSavePopup} deletePopup={deletePopup} setDeletePopup={setDeletePopup}   setCumulativeData={ setCumulativeData}
                      setCapitalData={setCapitalData} setIdStrategy={setIdStrategy}/>
    </>
}
export default SavedStrategyPage

