import React from 'react';


const HelpPage = () => {



    return<>
        <h1> Help</h1>
    </>
}
export default HelpPage

