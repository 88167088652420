import React, {
    Component, useLayoutEffect, useRef,
    useState
} from 'react';

import Chart
    from "react-apexcharts";
import {setUrl2} from "../../../store/configureStore";
import Select from "react-select";
import {customStyles} from "../../StrategyOverviewPage/ChangeStrategy/ChangeStrategy";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);


function ExecutionAganstPlanChart(props) {
    const chart = useRef(null);

    useLayoutEffect(() => {
        let chart = am4core.create("chartdiv", am4charts.XYChart);


        let data = [
            {
                "date": "1.May",
                "Planned": 220000000,
                "Executed": 227231115.65,
                "percent": 10
            }, {
                "date": "2.May",
                "Planned": 295000000.00,
                "Executed": 297671145.97,
                "percent": 10
            }, {
                "date": "1.Jun",
                "Planned": 295000000.00,
                "Executed": 304295611.46,
                "percent": 10
            }, {
                "date": "2.Jun",
                "Planned": 295000000.00,
                "Executed": 307338311.01,
                "percent": 10
            }, {
                "date": "1.Jul",
                "Planned": 150000000.00,
                "Executed": 265896751.96,
                "percent": 10
            }, {
                "date": "2.Jul",
                "Planned": 150000000.00,
                "Executed": 159745607.24,
                "percent": 10
            }, {
                "date": "1.Aug",
                "Planned": 150000000.00,
                "Executed": 156206091.29,
                "percent": 10
            }, {
                "date": "2.Aug",
                "Planned": 150000000.00,
                "Executed": 146606059.85,
                "percent": 10
            }, {
                "date": "1.Sep",
                "Planned": 150000000.00,
                "Executed": 154216355.25,
                "percent": 10
            }, {
                "date": "2.Sep",
                "Planned": 150000000.00,
                "Executed": 146605369.60,
                "percent": 10
            }, {
                "date": "1.Oct",
                "Planned": 150000000.00,
                "Executed": 150406401.59,
                "percent": 10
            }, {
                "date": "2.Oct",
                "Planned": 150000000.00,
                "Executed": 151815208.23,
                "percent": 10
            }, {
                "date": "1.Nov",
                "Planned": 150000000.00,
                "Executed": 144706600.01,
                "percent": 10
            }, {
                "date": "2.Nov",
                "Planned": 150000000.00,
                "Executed": 150455021.18,
                "percent": 10
            }, {
                "date": "1.Dec",
                "Planned": 150000000.00,
                "Executed": 150182732.53,
                "percent": 10
            }, {
                "date": "2.Dec",
                "Planned": 150000000.00,
                "Executed": 147434266.75,
                "percent": 10
            }, {
                "date": "1.Jan",
                "Planned": 150000000.00,
                "Executed": 154179012.89,
                "percent": 10
            }, {
                "date": "2.Jan",
                "Planned": 150000000.00,
                "Executed": 150203397.66,
                "percent": 10
            }, {
                "date": "1.Feb",
                "Planned": 150000000.00,
                "Executed": 305000000,
                "percent": 10
            }, {
                "date": "2.Feb",
                "Planned": 150000000.00,
                "Executed": 139323413.95,
                "percent": 10
            }, {
                "date": "1.Mar",
                "Planned": 150000000.00,
                "Executed": 0,
                "percent": 10
            }, {
                "date": "2.Mar",
                "Planned": 150000000.00,
                "Executed": 0,
                "percent": 10
            }, {
                "date": "1.Apr",
                "Planned": 150000000.00,
                "Executed": 0,
                "percent": 10
            }, {
                "date": "2.Apr",
                "Planned": 150000000.00,
                "Executed": 0,
                "percent": 10
            },

        ]

        chart.data = data
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.lineX.disabled = true;
        chart.cursor.lineY.disabled = true;
        chart.cursor.maxTooltipDistance = 0;
     chart.numberFormatter.numberFormat = "###,###,###,###,###."
        // x-axios

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "date";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.renderer.labels.template.fill = am4core.color("#B3B6C1");
        categoryAxis.renderer.grid.template.strokeWidth = 0
        categoryAxis.renderer.line.strokeOpacity = 1;
        categoryAxis.renderer.line.strokeWidth = 2;
        categoryAxis.renderer.line.stroke = am4core.color("#D5DDF1");
        categoryAxis.renderer.background.fill = am4core.color("#F8FAFE");
        categoryAxis.renderer.marginTop = 20;

        //title

        var title = chart.titles.create();
        title.text = "$ Raise";
        title.fontSize = 13;
        title.fill = am4core.color('#A7ACC1');
        title.marginBottom = 30;
        title.align = 'left'
        categoryAxis.cursorTooltipEnabled = false;

        // y-axios
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.max = 360000000;
        valueAxis.renderer.opposite = true;
        valueAxis.renderer.labels.template.fill = am4core.color("#B3B6C1");
        valueAxis.strictMinMax = true;
        valueAxis.renderer.minGridDistance = 60;
        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.strokeWidth = 2;
        valueAxis.renderer.line.stroke = am4core.color("#D5DDF1");
        valueAxis.renderer.labels.template.fontSize = 11;
        valueAxis.renderer.grid.template.strokeDasharray = 4;
        valueAxis.title.fontWeight = 800;
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.renderer.labels.template.adapter.add("text", (label, target, key) => {
            return label ? '$' + label.split(',')[0] + 'm' : null
        });

        // Create series
        function createSeries(field, name) {
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = field;
            series.dataFields.categoryX = "date";
            series.name = name;
            series.clustered = false;
            series.dataFields.percent = "percent";
            series.columns.template.width = am4core.percent(50);
            series.tooltip.getFillFromObject = false;
            series.tooltip.getStrokeFromObject = false;
            series.tooltip.background.stroke = am4core.color("#000");
            series.tooltip.background.fill = am4core.color("#000");
            // series.fill = am4core.color("#DCF0E9");
            series.tooltipText = "{categoryX}: [b]{valueY}[/]";
            let columnTemplate = series.columns.template;
            columnTemplate.strokeOpacity = 0;

            columnTemplate.adapter.add("fill", function (fill, target) {

                if (name === 'Executed') {
                    if (target.dataItem && (target.dataItem.valueY < 150000000)) {

                        return am4core.color("#9ae5d9");
                    } else if (target.dataItem && (target.dataItem.valueY >= 150000000) && (target.dataItem.valueY <= 300000000)) {

                        return am4core.color("#29D8AE");
                    } else if (target.dataItem && (target.dataItem.valueY > 300000000)) {
                        return am4core.color("#0FBB91");
                    }
                } else {
                    return am4core.color("#DCF0E9");
                }
            })
            //


            series.adapter.add("tooltipText", function (ev) {
                let text = "[bold]{categoryX}[/]\n "
                let percent = "Daily Turnover: {percent}%"

                chart.series.each(function (item) {

                    if (!item.isHidden) {
                        text += "[" + item.fill.hex + "]●[/] " + item.name + ": {" + item.dataFields.valueY + "}\n";
                    }
                });
                return text + percent;
            });

        }

        createSeries('Planned', 'Planned')
        createSeries('Executed', 'Executed')

        return () => {
            chart.dispose();
        };
    }, []);

    return (
        <div id="chartdiv" style={{width: "100%", height: "500px"}}></div>
    );
}

const ChartExecutionAgainstPlan = () => {
    let options1 = [
        {value: 'FY20', label: 'FY20'},
        {value: 'FY202', label: 'FY202'},
        {value: 'FY203', label: 'FY203'},

    ]
    return <>
        <div className="wrapper-chart">
            <div className="top-row">
                <h3>Execution Against Plan</h3>
                <Select
                    defaultValue={options1[0].value}
                    placeholder={options1[0].value}
                    options={options1}
                    name='select-chart-data'
                    className='select-chart-data select'
                    styles={customStyles}
                    width='inherit'
                />
            </div>
            <div className="chart-img">
                <ExecutionAganstPlanChart/>
                {/*<img src={setUrl2("images/chart-execution-against-plan.svg")} alt=""/>*/}
            </div>
        </div>
    </>
}
export default ChartExecutionAgainstPlan

