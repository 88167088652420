import React
    , {useEffect, useState} from 'react';
import {setUrl2} from "../../store/configureStore";
import ChartCapitalSequencing
    from "../GraphsCharts/ChartCapitalSequencing/ChartCapitalSequencing";

import {Formik, Field, Form} from 'formik';
import DatePicker from "react-datepicker";
import NumberFormat from 'react-number-format';
import * as Yup from 'yup';
import ChartCumulativeCapitalChangePlan from "../ChartCumulativeCapitalChangePlan/ChartCumulativeCapitalChangePlan";

function generateNewStrategy({amount, capital, endDate, startDate, term, chart}) {

    let value = null

    return generateChartData(endDate, startDate, term, amount, chart)
}

function generateChartData(endDate, startDate, term, amount, chart) {
    let categories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    let graphTerm
    let columnValue
    let endPeriod
    if (term == 'Weekly') {
        graphTerm = 4
        columnValue = amount / 12 * 4
    } else if (term === 'Fortnightly') {
        graphTerm = 2
        columnValue = amount / 12 * 2
    } else if (term === 'Monthly') {
        graphTerm = 1
        columnValue = amount / 12
    }
    let axiosData = []

    for (var i = startDate.getMonth(); i <= (endDate.getMonth() + (12 * (endDate.getFullYear() - startDate.getFullYear()))); i++) {
        for (let j = 0; j < graphTerm; j++) {
            axiosData.push({

                date: `${j + 1}. ${i >= 12 ? categories[i % 12] + ' ' + ((startDate.getFullYear() + Math.floor(i / 12))) : (categories[i])}`,
                value: columnValue
            })
        }

    }

    return axiosData;
}

const SignupSchema = Yup.object().shape({
    amount: Yup.string()
        .required('Enter the value'),

});
const EditStrategyPage = ({setClosePopup, setExecutionPopup, setSavePopup, cumulativeData, setCumulativeData, capitalData, setCapitalData, setIdStrategy, idStrategy, setnameStrategy2}) => {
    let editData = JSON.parse(localStorage.getItem('strategies'))[idStrategy]
    const [startDate, setStartDate] = useState(editData.startDate);
    const [endDate, setEndDate] = useState(editData.endDate);
    const [dataChart, setDataChart] = useState();
    const [amountChart, setAmountChart] = useState(editData.amount);
    const [nameStrategy, setNameStrategy] = useState();
    useEffect(() => {
        setDataChart(capitalData)
    }, [])

    return <>
        <h1>Edit Strategy</h1>
        <div className="form-new-strategy">
            <Formik initialValues={{
                nameStrategy: editData.name,
                capital: editData.capital,
                term: editData.term,
                startDate: new Date(editData.startDate),
                endDate: new Date(editData.endDate),
                floorPrice: '',
                ceilingPrice: '',
                amount: editData.amount,
                note: editData.note
            }} onSubmit={/*async*/ (values, setSubmitting) => {
                setDataChart(generateNewStrategy({...values}))
                setAmountChart(values.amount)
                setNameStrategy(values.nameStrategy)
            }}
                    validationSchema={SignupSchema}
                    validateOnChange={true}>
                {({isSubmitting, values, setFieldValue, errors}) => (
                    <Form
                        className={values.capital === 'BuybackCapital' ? 'buyback-section' : values.capital == 'RaiseCapital' ? 'raise-section' : ''}>
                        <div className="wrapper-input">
                            <Field id="name-strategy" type="text" name="nameStrategy" placeholder="Name of Strategy"/>
                        </div>
                        <div className="wrapper-data-form wrapper-fon">
                            <div className="flex-layout ">
                                <div className="col radio-group-two">
                                    <h5>Select strategy</h5>
                                    <div className="flex-layout">
                                        <label>
                                            <Field type="radio" name="capital" value="RaiseCapital"/>
                                            <span>Raise Capital</span>
                                        </label>
                                        <label>
                                            <Field type="radio" name="capital" value="BuybackCapital"/>
                                            <span>Buyback Capital</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col calendar-col">
                                    <div className="wrapper-amount">
                                        <label htmlFor="amount">Amount</label>
                                        <NumberFormat className='amount'
                                            // onValueChange={amount => setFieldValue('amount', amount.value)}
                                                      id="amount" thousandSeparator={true} prefix={'$'} name="amount"
                                                      placeholder="$XXX.XXm" inputMode="numeric"
                                                      value={Math.trunc(editData.amount)}/>
                                    </div>
                                    <label htmlFor="">Start-End</label>
                                    <div className="flex-layout">
                                        <div className="wrapper-calendar">
                                            <DatePicker selected={values.startDate} dateFormat="MMMM d, yyyy"
                                                        className="form-control" name="startDate"
                                                        onChange={date => {
                                                            setFieldValue('startDate', date)
                                                            setStartDate(date)
                                                        }}/>
                                        </div>
                                        <span className='line'> - </span>
                                        <div className={"wrapper-calendar" + (startDate > endDate ? ' error' : '')}>
                                            <DatePicker selected={values.endDate} dateFormat="MMMM d, yyyy"
                                                        className="form-control" name="endDate"
                                                        onChange={date => {
                                                            setFieldValue('endDate', date)
                                                            setEndDate(date)
                                                        }}/>
                                        </div>
                                        {(startDate > endDate) &&
                                        <div className='error-data'>Invalid date range selected</div>
                                        }

                                    </div>
                                </div>
                                <div className="col radio-group-three">
                                    <h5>Calendar</h5>
                                    <div className="radio-group-three-row flex-layout">
                                        <label>
                                            <Field type="radio" name="term" value='Weekly'/>
                                            <span>Weekly</span>
                                        </label>
                                        <label>
                                            <Field type="radio" name="term" value='Fortnightly'/>
                                            <span>Fortnightly</span>

                                        </label>
                                        <label>
                                            <Field type="radio" name="term" value='Monthly'/>
                                            <span>Monthly</span>
                                        </label>
                                    </div>
                                    <div className="row-price flex-layout">
                                        <div className="col-price">
                                            <label htmlFor="amount1">Floor Price</label>
                                            <NumberFormat className='amount' id="amount1" thousandSeparator={true}
                                                          prefix={'$'} name="floorPrice" placeholder="$XX.XX"
                                                          inputMode="numeric"/>
                                        </div>
                                        <div className="col-price">
                                            <label htmlFor="amount2">Ceiling Price</label>
                                            <NumberFormat className='amount' id="amount2" thousandSeparator={true}
                                                          prefix={'$'} name="ceilingPrice" placeholder="$XX.XX"
                                                          inputMode="numeric"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="data-info-new-strategy">
                                <div className="col">
                                    <h5>Est. Capital Base Issuance</h5>
                                    <span className='price'><span>YY.Y</span>%</span>
                                    <span className='remaining'>$<span>XXX.XX</span>m remaining</span>
                                </div>
                                <div className="col">
                                    <h5>Peak Market Impact</h5>
                                    <span className='price'>LOW</span>
                                    <span className='remaining'>Occurs during<span>XXX, YYY, ZZZ </span>months</span>
                                </div>
                                {values.amount && startDate <= endDate ?
                                    <button className='bg-full submit-form-btn' type='submit'>submit</button> :
                                    <button className='bg-full submit-form-btn' type='submit' disabled>submit</button>
                                }
                            </div>

                        </div>
                        <ChartCapitalSequencing type={values.capital} data={dataChart} amountChart={amountChart}
                                                setCapitalData={setCapitalData}  setAmountChart={setAmountChart}/>

                        <ChartCumulativeCapitalChangePlan type={values.capital} data={values} dinamicData={capitalData}
                                                          setCumulativeData={setCumulativeData}>
                            <div className="top-row">
                                <h3>Cumulative Capital Change</h3>
                            </div>
                        </ChartCumulativeCapitalChangePlan>

                        <div className="fixed-row-btns">
                            <button onClick={() => {
                                setClosePopup(true)
                                setExecutionPopup(true)
                            }} type="button" className='execute-btn bg-none'>Execute
                            </button>
                            <button onClick={() => {
                                setClosePopup(true)
                                setSavePopup(true)
                                setnameStrategy2( values.nameStrategy)
                                let current = new Date();
                                let strategies = localStorage.getItem('strategies') ? JSON.parse(localStorage.getItem('strategies')) : {};
                                strategies[idStrategy] = {
                                    'id': idStrategy,
                                    'data': capitalData,
                                    'name': values.nameStrategy,
                                    'cumulativeData': cumulativeData,
                                    'status': 'Authorised',
                                    'active': false,
                                    'date': current.getFullYear(),
                                    'amount': amountChart,
                                    'term': values.term,
                                    'startDate': values.startDate,
                                    'endDate': values.endDate,
                                    'capital': values.capital
                                }
                                localStorage.setItem('strategies', JSON.stringify(strategies));
                            }} type="button" className='save-btn bg-full'>Save
                            </button>
                        </div>
                        <div className="wrapper-fon">
                            <h3>Notes</h3>
                            <div className="wrapper-style-text">
                                <span className='bold-text active'></span>
                                <span className='underline-text'></span>
                                <span className='align-text'></span>
                            </div>
                            <Field id="note" name="note" placeholder="Type a message here" component="textarea"/>
                        </div>
                    </Form>)}
            </Formik>
        </div>
    </>
}
export default EditStrategyPage

