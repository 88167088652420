import React from 'react';
import {Link} from "react-router-dom";
import {setUrl} from "../../../store/configureStore";

const RowTableData = ({
                          name, status, amount, date, active, cumulativeData, data, setClosePopup, setDeletePopup, setCumulativeData,
                          setCapitalData, id, setIdStrategy
                      }) => {

    if (amount >= 1000000 && amount < 1000000000 ) {
        if (amount % 1000000 != 0) {
            amount = Math.round(amount / 1000000) + '.00m'
        } else {
            amount = Math.round(amount / 1000000) + 'm'
        }
    }else if(amount >= 1000000000){
        if (amount % 1000000000 != 0) {
            amount = Math.round(amount / 1000000000) + '.00b'
        } else {
            amount = Math.round(amount / 1000000000) + 'b'
        }
    }
    return <>
        <div className="row">
            <div className="col name ">
                <span className={active ? "chosen active" : "chosen"}></span>
                {name}
            </div>
            <div className="col status">{status}</div>
            <div className="col amount">${amount}</div>
            <div className="col date">{date}</div>
            <div className="col action">

                <Link data-id='' className='edit' to={setUrl('/edit')} onClick={() => {
                    setCumulativeData(cumulativeData)
                    setCapitalData(data)
                    setIdStrategy(id)
                }}></Link>
                <span onClick={() => {
                    setClosePopup(true)
                    setDeletePopup(true)
                }} className='delete'></span>
            </div>
        </div>
    </>
}
export default RowTableData

