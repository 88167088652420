import React, {
    Component, useLayoutEffect, useRef,
    useState
} from 'react';
import {setUrl2} from "../../../store/configureStore";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);


function ExecutionBrokerChart(props) {
    const chart = useRef(null);

    useLayoutEffect(() => {
        let chart = am4core.create("chartdiv5", am4charts.PieChart);




                chart.data = [ {
            "country": "Citigroup Participate",
            "litres": 21
        }, {
            "country": "USB Desk",
            "litres": 20
        }, {
            "country": "Liquidnet",
            "litres": 12
        }, {
            "country": "Credit Sulsee Volume Inline",
            "litres":9
        }, {
            "country": "Team ECitigroup Cash Desk",
            "litres":9
        }, {
            "country": "JP Morgan Dynamic",
            "litres": 7
        },
                    {
            "country": "Goldman Sach Program Desk",
            "litres": 5
        },
                    {
            "country": "CLSA Volume Inline",
            "litres": 4
        },
                    {
            "country": "CLSA WO",
            "litres": 3
        },
                    {
            "country": "Morgan Stanley Dynamic",
            "litres": 3
        },
                    {
            "country": "Macquarie Price Inline",
            "litres": 3
        },
                    {
            "country": "Macquarie Price Inline",
            "litres": 2
        },
                    {
            "country": "ITG Cash Desk",
            "litres":1
        },
                    {
            "country": "Canaccord Cash Desk",
            "litres":1
        },
                    {
            "country": "Austrafan Stock Exchange",
            "litres":1
        },
                    {
            "country": "Chi-X Australia",
            "litres":2
        }
        ];

// Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "litres";
        console.log(pieSeries.dataFields)
        pieSeries.dataFields.category = "country";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeOpacity = 1;
        // pieSeries.slices.template.cornerRadius = 100
// This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
     //   pieSeries.hiddenState.properties.endAngle = -90;
      //  pieSeries.hiddenState.properties.startAngle = -90;

        pieSeries.ticks.template.disabled = true;
        pieSeries.labels.template.disabled = true;
        chart.hiddenState.properties.radius = am4core.percent(0);
        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";
        var marker = chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12, 12, 12, 12);
        chart.legend.markers.template.width = 10;
        chart.legend.markers.template.height = 10;
        chart.legend.labels.template.fontSize = 11
        chart.legend.fontSize = 11;
         pieSeries.tooltip.getFillFromObject = false;
         pieSeries.tooltip.getStrokeFromObject = false;
         pieSeries.tooltip.background.stroke = am4core.color("#000");
         pieSeries.tooltip.background.fill = am4core.color("#000");
        pieSeries.colors.step = 3;
        console.log(chart.legend)
        return () => {
            chart.dispose();
        };
    }, []);

    return (
        <div id="chartdiv5" style={{width: "100%", height: "600px"}}></div>
    );
}
const ChartExecutionBroke = () => {

    return <>
        <div className="wrapper-chart">
            <div className="top-row">
                 <h3>Execution Broker</h3>
            </div>
            <div className="chart-img">
                <ExecutionBrokerChart/>
                {/*<img src={setUrl2("images/chart-pie1.png")} alt=""/>*/}
            </div>
        </div>
     </>
}
export default ChartExecutionBroke

